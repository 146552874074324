// Navigation Bar SECTION



const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Stefano",
  middleName: "",
  lastName: "Leone",
  message: "As an adept Product Manager, I specialize in harmonizing UX design, business acumen, and technology, with a particular focus on AI and Blockchain technologies.",
  icons: [

    {
      image: "fa-github",
      url: "https://github.com/stefanoleone",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/stefanoleone",
    },
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/hashirshoaeb.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/Stefano.png"),
  imageSize: 375,
  message:
    "Hello there! I'm Stefano Leone, a tech enthusiast deeply rooted in the world of AI and Blockchain. Based in Zurich with a history in Milan, I'm shaping the future at IOHK Singapore with our DApp Certification Program. Beyond work, I founded the Ethereum Milano Community, uniting over 2,200 blockchain fans. I also curate the 'AI for Product Pioneers' newsletter and created the WatchOut! app loved by smartwatch users globally. Educated in Milan and further refined at Carnegie Mellon, I'm always learning. Outside tech, I'm passionate about space exploration, sports, and astronomy. Want to chat about the stars, AI, or digital identity? Let's connect!",
  resume: require("../editable-stuff/StefanoLeoneResume.pdf"),


};

const newsletter = {
  show: true,
  heading: "AI for Product Pioneers Newsletter",
  imageLink: require("../editable-stuff/AI4ProductPioneers_logo.png"),
  imageSize: 270,
  message:
    "Explore AI in product management with weekly insights from AI for Product Pioneers.",
  newsletterlink: "https://www.linkedin.com/newsletters/7058015512634511361",

};

const services = {
  show: true,
  heading: "Services",
  imageLink: require("../editable-stuff/AllThingsBlockchain_full-logo_s.png"),
  imageSize: 270,
  message:
    "directly edited into the services.jsx file",

};


// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: false,
  heading: "Recent Projects",
  gitHubUsername: "stefanoleone", //i.e."johnDoe12Gh"
  reposLength: 4,
  specificRepos: [],
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
  images: [
    {
      img: require("../editable-stuff/Stefano.png"),
      label: "First slide label",
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum."
    },
    {
      img: require("../editable-stuff/Stefano.png"),
      label: "Second slide label",
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum."
    },
  ],
  imageSize: {
    width:"615",
    height:"450"
  }
};

// SKILLS SECTION
const skills = {
  show: false,
  heading: "Skills",
  hardSkills: [
    { name: "Python", value: 90 },
    { name: "SQL", value: 75 },
    { name: "Data Structures", value: 85 },
    { name: "C/C++", value: 65 },
    { name: "JavaScript", value: 90 },
    { name: "React", value: 65 },
    { name: "HTML/CSS", value: 55 },
    { name: "C#", value: 80 },
  ],
  softSkills: [
    { name: "Goal-Oriented", value: 80 },
    { name: "Collaboration", value: 90 },
    { name: "Positivity", value: 75 },
    { name: "Adaptability", value: 85 },
    { name: "Problem Solving", value: 75 },
    { name: "Empathy", value: 90 },
    { name: "Organization", value: 70 },
    { name: "Creativity", value: 90 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: false,
  heading: "Services",
  message:"As an adept Product Manager specializing in harmonizing UX design, business acumen, and technology, my focus on AI and Blockchain technologies positions me uniquely to offer tailored B2B services. I excel in elevating both your product management processes and your end product. My expertise extends to a wide array of AI technologies, such as machine learning algorithms, computer vision, and reinforcement learning, in addition to large language models (LLMs). These cutting-edge technologies can be integrated to optimize a range of product management activities, from idea validation to strategic planning. Furthermore, I can directly weave AI capabilities like chatbots, sentiment analysis, pattern recognition, and natural language processing into your product, enriching the user experience. Whether you're seeking a project-specific product manager, a strategy-driving product owner, or a tech-savvy AI consultant, my services are meticulously crafted to meet your unique needs. Let's collaborate to ensure your product remains innovative, user-centric, and ahead of the curve.",  email: "",
};

const experiences = {
  show: false,
  heading: "Experiences",
  data: [
    {
      role: 'Software Engineer',// Here Add Company Name
      //companylogo: require('../assets/img/dell.png'),
      date: 'June 2018 – Present',
    },
    {
      role: 'Front-End Developer',
      //companylogo: require('../assets/img/boeing.png'),
      date: 'May 2017 – May 2018',
    },
  ]
}

// Blog SECTION
 const blog = {
   show: false,
 };

export { navBar, mainBody, about, newsletter, services, repos, experiences, leadership, skills, getInTouch, blog};
