import React from "react";

import axios from "axios";
import { Jumbotron } from "./migration";

const pictureLinkRegex = new RegExp(
  /[(http(s)?):(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
);

const Services = ({ heading, message, link, imgSize, email }) => {
  const [profilePicUrl, setProfilePicUrl] = React.useState("");
  const [showPic, setShowPic] = React.useState(Boolean(link));
  // https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook
  React.useEffect(() => {
    const handleRequest = async () => {
      const instaLink = "https://www.instagram.com/";
      const instaQuery = "/?__a=1";
      try {
        const response = await axios.get(instaLink + link + instaQuery);
        setProfilePicUrl(response.data.graphql.user.profile_pic_url_hd);
      } catch (error) {
        setShowPic(false);
        console.error(error.message);
      }
    };

    if (link && !pictureLinkRegex.test(link)) {
      handleRequest();
    } else {
      setProfilePicUrl(link);
    }
  }, [link]);



  return (
    <Jumbotron id="services" className="m-0">
      <div className="container row">
        <div className="col-5 d-none d-lg-block align-self-center">
          {showPic && (
            <img
              className="border border-secondary"
              src={profilePicUrl}
              alt="profilepicture"
              //width="200"
              //height="50"
            />
          )}
        </div>
        <div className={`col-lg-${showPic ? "7" : "12"}`}>
          <h2 className="display-4 mb-5 text-center">{heading}</h2>
          
          <p>As a product manager, startup mentor, and entrepreneur specializing in <strong>Blockchain</strong> and <strong>AI</strong>, I offer tailored B2B services to elevate your product management processes and products:</p>
            <ul>
                <li>🔍 <strong>AI-Enhanced Product Management</strong>: Integrate large language models (LLMs) into product management for enhanced efficiency in idea validation, product planning, pricing, and Go-To-Market strategies.</li>
                
                <li>🤖 <strong>AI Native Products</strong>: Implement advanced AI technologies like chatbots, sentiment analysis, pattern recognition, fraud detection, and natural language processing to deliver customer-focused products.</li>
                
                <li>🧩 <strong>AI and Blockchain Advisory</strong>: Navigate the complexities of blockchain and AI with strategic and technical guidance. Services include blockchain solutions, AI implementation, market insights, and innovation strategies.</li>
                
                 <li>🌟 <strong>Customized Service Offering</strong>: Whether you need a project-specific product manager, strategy-oriented product owner, or guidance for AI technology integration, my services are customized to meet your unique needs.</li>

                
            </ul>
          
          
          {email && (
            <p className="lead text-center">
              <a
                className="btn btn-outline-dark btn-lg"
                href={email}
                target="_blank"
                rel="noreferrer noopener"
                role="button"
                aria-label="email/CV"
              >
                Contact
              </a>
            </p>
          )}
        </div>
      </div>
    </Jumbotron>
  );
};

export default Services;
